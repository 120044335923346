const deorphanize = (selector, orphans) => {
  const obj = document.querySelectorAll(selector);
  const objLength = obj.length;
  const orphansLength = orphans.length;
  for (var i = 0; i < objLength; i++) {
    let textReplace = obj[i].innerHTML;
    for (var j = 0; j < orphansLength; j++) {
      const textSplit = textReplace.split(" " + orphans[j] + " ");
      textReplace = textSplit.join(" " + orphans[j] + "&nbsp;");
    }
    obj[i].innerHTML = textReplace;
  }
};

export default deorphanize;
