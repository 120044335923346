import entersViewport from "./entersViewport";

class swmSearch {
  constructor({
    searchContainer,
    searchInput,
    searchScroll,
    resultsContainer,
    searchPlaceholder,
    handleSearchFn,
  }) {
    let searchIsOpened = false;
    let searchState = "closed";
    let searchPage = 0;
    let searchTimer;
    let searchPhrase = "";
    const navBar = document.querySelector(".navbar");

    this.searchOpen = () => {
      searchContainer.classList.add("search--opened");
      document.body.classList.add("searchOpened");
      navBar.classList.remove("navbar--opened");
      searchIsOpened = true;
      searchState = "opened";
      searchInput.focus();
    };

    this.searchClose = () => {
      searchContainer.classList.remove("search--opened");
      document.body.classList.remove("searchOpened");
      searchInput.value = "";
      searchPhrase = "";
      searchIsOpened = false;
      searchState = "closed";
    };

    this.getSearchContainer = () => searchContainer;

    this.getSearchInput = () => searchInput;

    this.searchToggle = () =>
      searchIsOpened ? this.searchClose() : this.searchOpen();

    this.searchPhrase = () => searchPhrase;
    searchInput.addEventListener("keyup", () => {
      clearTimeout(searchTimer);
      searchPhrase = searchInput.value;
      if (searchPhrase.length) {
        searchState = "searching";
        searchContainer.classList.add("search--searching");
        resultsContainer.innerHTML = "";
        searchTimer = setTimeout(function () {
          var result = handleSearchFn(searchPhrase, searchPage);
          if (result) {
            searchState = "results";
            searchContainer.classList.add("search--results");
          } else {
            searchState = "completed";
            searchContainer.classList.remove("search--results");
          }
          searchContainer.classList.remove("search--searching");
        }, 300);
      } else {
        resultsContainer.innerHTML = "";
        searchState = "opened";
        searchContainer.classList.remove("search--searching");
      }
    });

    searchInput.addEventListener("blur", (e) => {
      if ((!e.relatedTarget || e.relatedTarget.id !== "searchToggle") && !searchPhrase.length) {
        this.searchClose();
      }
    });

    searchScroll.addEventListener(
      'scroll',
      () => {
        if (
          searchState != 'completed' &&
          searchState != 'searching' &&
          entersViewport(searchPlaceholder)
        ) {
          searchState = 'searching';
          searchPage++;
          searchContainer.classList.add('search--searching');
          var result = handleSearchFn(searchPhrase, searchPage);
          if (result) {
            searchState = 'results';
          } else {
            searchState = 'completed';
          }
          searchContainer.classList.remove('search--searching');
        }
      },
      { passive: true }
    );
  }
}

export default swmSearch;
