import deorphanize from "./components/deorphanize";
import swmNavBar from "./components/swmNavBar";
import swmSearch from "./components/swmSearch";
import SimpleScrollbar from 'simple-scrollbar';
import LazyLoad from "vanilla-lazyload";
import './components/modal';
import 'hyphenopoly/Hyphenopoly_Loader';

(function () {
    

    deorphanize("h1, h2, h3, h4, h5, h6, p, a, li", [
        "a",
        "i",
        "o",
        "u",
        "w",
        "z",
        "A",
        "I",
        "O",
        "U",
        "W",
        "Z",

    ]);
    swmNavBar();

    new LazyLoad();

    // Search implementation
    const sampleResults = `
                <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    <a href="#" class="articlePreview">
        <div class="articlePreview__imageWrapper">
            <img class="articlePreview__image" src="/wp-content/themes/nswm/assets/test/article-thumbnail.jpg" alt="">
        </div>
        <div class="articlePreview__categories">
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">blog</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">boliwia</span>
    
                        <span onclick="swmGoTo(event, #)" class="articlePreview__categoryLink">misje</span>
    
                </div>
        <h3 class="articlePreview__title">app.js w&nbsp;kwarantannie, czyli Święte Triduum Paschalne</h3>
        <div class="articlePreview__date">
            22 kwietnia 2020
        </div>
    </a>
    `


    const searchContainer = document.getElementById("searchContainer");
    SimpleScrollbar.initEl(searchContainer);
    const searchScroll = searchContainer.querySelector(".ss-content");
    const searchInput = document.getElementById("searchInput");
    const searchToggle = document.getElementById("searchToggle");
    const searchPlaceholder = document.getElementById("searchPlaceholder");
    const searchResultsContainer = document.getElementById("searchResultsContainer");
    const searchClose = document.querySelector(".search__close");

    const handleSearchFn = function (searchPhrase, page) {
        console.log(searchPhrase, page);
        if (page < 3) {
            const resultsFragment = document.createElement("template");
            resultsFragment.innerHTML = sampleResults;
            searchResultsContainer.appendChild(resultsFragment.content);

            return true;
        }
    }


    const search = new swmSearch({
        searchContainer: searchContainer,
        searchScroll: searchScroll,
        searchInput: searchInput,
        handleSearchFn: handleSearchFn,
        searchPlaceholder: searchPlaceholder,
        resultsContainer: searchResultsContainer
    });


    searchToggle.addEventListener("click", search.searchToggle);
    searchClose.addEventListener("click", search.searchClose);


    const emptyAnchors = document.querySelectorAll('a[href="#"]');
    for (var i = 0; i < emptyAnchors.length; i++) {
        emptyAnchors[i].onclick = function (e) {
            e.preventDefault();
        }
    }

})()