(function () {
    const modals = document.querySelectorAll(".modal__wrapper");

    for (const modal of modals) {
        const closeButton = modal.querySelector(".modal__close");
        closeButton.addEventListener("click", () => {
            modal.classList.remove("active");
        })
    }

    const openButtons = document.querySelectorAll(".modal__open");

    for (const button of openButtons) {
        button.addEventListener("click", () => {
            const modal = document.querySelector(button.getAttribute("data-target"));
            if (modal) {
                modal.classList.add("active");
            }
        })
    }
})();