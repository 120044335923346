const handleHomeNavbar = (navBar, scrollClass) => {
  window.addEventListener(
    'scroll',
    () => {
      if (
        window.scrollY > 40 ||
        window.pageYOffset > 40 ||
        document.body.scrollTop +
          (document.documentElement && document.documentElement.scrollTop) >
          100
      ) {
        navBar.classList.remove(scrollClass);
      } else {
        navBar.classList.add(scrollClass);
      }
    },
    { passive: true }
  );
};

const handleMobileNavToggle = (navBar) => {
  navBar.classList.toggle("navbar--opened");
};

const swmNavBar = () => {
  const navBar = document.querySelector(".navbar");
  const mobileNavToggle = document.getElementById("mobileNavToggle");

  navBar.classList.contains("navbar--scrollTransparent") ? handleHomeNavbar(navBar, "navbar--scrollTransparent") : null;
  navBar.classList.contains("navbar--scrollWhite") ? handleHomeNavbar(navBar, "navbar--scrollWhite") : null;
  mobileNavToggle.addEventListener("click", (e) => {
    e.preventDefault();
    handleMobileNavToggle(navBar);
  });
};

export default swmNavBar;
